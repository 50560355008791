.dialog-title {
  display: flex;
  span {
    flex: 1;
    text-align: center;
    margin-right: 30px;
  }
}
.filter {
  padding: 39px 0 29px 0;
  height: 165px;
  box-shadow: 0px 1px 0px #24272d;
  .filter-title {
    margin-left: 24px;
  }
  .filter-option {
    display: flex;
    margin-top: 15px;
    width: 90%;
    justify-content: space-around;
    margin-left: 12px;

    .filter-item {
      flex: 1;
      margin-right: 0px;
      width: 230px;
    }
  }
}
