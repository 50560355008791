:root {
  --PRIMARY-COLOR: #44d62c;
  --PRIMARY-COLOR-LIGHT: rgba(88, 186, 71, 0.15);
  --BACKGROUND-COLOR: #0f0f0f;
  --BACKGROUND-OUT-COLOR: #151515;
  --BACKGROUND-COLOR-BUTTON: #262629;
  --TEXT-COLOR: #f5f5f5;
  --TEXT-COLOR-WHITE: #fff;
  --TEXT-COLOR-DISABLE: rgba(255, 255, 255, 1);
  --FONT-SIZE-NORMAL: 14px;
  --FONT-SIZE-BIG: 18px;
}

body {
  margin: 2px;
  /* font-family: -apple-system, SF pro, SF Pro Display,"Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.rdrDateRangePickerWrapper {
  font-family: "SF Pro Display";
}

.rdrDateDisplayWrapper {
  display: none;
}
.rdrDefinedRangesWrapper {
  display: none;
}

/* NAVIGATOR */
.rdrMonthAndYearWrapper {
  background-color: var(--BACKGROUND-COLOR);
}

/* Select Month */
.rdrMonthAndYearPickers .rdrMonthPicker select {
  color: var(--TEXT-COLOR);
}
/* Select Year */
.rdrMonthAndYearPickers .rdrYearPicker select {
  color: var(--TEXT-COLOR);
}

.rdrMonthPicker select * {
  color: var(--TEXT-COLOR);
  background-color: var(--BACKGROUND-COLOR);
}
.rdrYearPicker select * {
  color: var(--TEXT-COLOR);
  background-color: var(--BACKGROUND-COLOR);
}

button.rdrNextPrevButton {
  border-radius: 100%;
}

.rdrMonths {
  background-color: var(--BACKGROUND-COLOR);
}

.rdrMonth > .rdrMonthName {
  color: var(--TEXT-COLOR);
  text-align: center;
  font-size: var(--FONT-SIZE-BIG);
}

.rdrWeekDays {
  margin-bottom: 5px;
}

.rdrWeekDays .rdrWeekDay {
  color: var(--TEXT-COLOR-WHITE);
  opacity: 0.2;
  font-size: var(--FONT-SIZE-NORMAL);
}

.rdrDays {
  gap: 3px;
}

button.rdrDay {
  width: calc(calc(100% / 7) - 2.7px);
}

button.rdrDay:not(.rdrDayDisabled) {
  background-color: var(--BACKGROUND-BUTTON);
  border-radius: 8px;
}

button.rdrDay .rdrDayNumber > span {
  color: var(--TEXT-COLOR);
  font-size: var(--FONT-SIZE-NORMAL);
}

button.rdrDay.rdrDayHovered {
  background-color: var(--PRIMARY-COLOR);
}

.rdrDayToday .rdrDayNumber span:after {
  background: var(--PRIMARY-COLOR) !important;
}

.rdrStartEdge,
.rdrEndEdge,
.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  color: var(--PRIMARY-COLOR) !important;
}

.rdrInRange {
  color: var(--PRIMARY-COLOR-LIGHT) !important;
}

.rdrDayPassive,
.rdrDayDisabled {
  background-color: transparent !important;
}

button.rdrDay.rdrDayPassive .rdrDayNumber > span,
button.rdrDay.rdrDayDisabled .rdrDayNumber > span {
  opacity: 0.1;
}

button > span.rdrSelected {
  color: var(--PRIMARY-COLOR) !important;
}