.layout__add {
  display: flex;
  width: 100%;
  height: 100vh;
}
.layout__left {
  /* flex: 1; */
  padding: 32px 60px;
  width: 50%;
  position: relative;
  background: radial-gradient(
    45.38% 45.38% at 50.12% 50.08%,
    rgba(68, 214, 44, 0.12) 24.87%,
    rgba(255, 255, 255, 0) 93.22%
  );
  box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.03);
  .titleDetail {
    position: absolute;
    top: 40%;
    left: 59px;
    max-width: 523px;
    margin-left: 16px;
  }
}
.layout__right {
  width: 50%;
  padding: 35px 0 0 0;
  background-color: #202020;
  height: 100vh;
  .btn-exit {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 48px;
    margin-left: 48px;
    margin-bottom: 24px;
    justify-content: space-between;
    & button {
      width: 97px;
    }
  }

  .btn-exit-login {
    margin-top: 30px;
    position: absolute;
    right: 48px;
    & button {
      width: 97px;
    }
  }

  & .alert-forgot {
    background-color: #44d62c;
    height: 51px;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 50%;
    & div {
      text-align: center;
      display: flex;
      align-items: center;
      margin: auto;
      text-align: center;
    }
  }
  & .layout-invite {
    margin-top: calc((100vh - 200px) / 2 - 92px);
    & .title-send-invite {
      text-align: center;
      margin-bottom: 40px;
    }
  }
  & .form-invite {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    padding-left: 94px;
    padding-right: 94px;
  }
  & .form-signIn {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-left: 94px;
    padding-right: 94px;
    & span {
      cursor: pointer;
    }
  }
  & .form-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: calc(100vh - 97px);
    width: 100%;
    padding-left: 94px;
    padding-right: 94px;
    & .form-input {
      max-height: calc(100vh - 248px);
      overflow-y: scroll;
      padding-top: 10px;
      width: 100%;
    }
    & .main-media {
      display: flex;
      align-items: flex-start;
      width: 100%;
      flex-wrap: wrap;
      & label {
        .file-name {
          text-align: center;
          font-size: 12px;
          margin-top: 8px;
          margin-bottom: 8px;
          margin-right: 12px;
        }
      }
    }
    & h3 {
      margin-bottom: 23px;
      text-align: center;
      /* width: 375px; */
    }
    & .alert {
      position: absolute;
      width: 100%;
      padding: 0 32px;
      bottom: 84px;
    }
    & .footter-process {
      width: 100%;
      position: absolute;
      bottom: 0px;
      height: 74px;
      background-color: #202020;
      z-index: 10;
      .btn-add-trailer {
        border-top: 1px solid #c4c4c4;
        padding: 16px 94px;
        & button {
          width: 100%;
        }
      }
      .btn-process {
        padding: 16px 48px;

        position: relative;
        & button:last-child {
          position: absolute;
          right: 48px;
        }
      }
    }
  }
}
