.switch-cus {
  display: flex;
  width: 100px;
  align-items: center;
  .switch-cus-text {
    margin-left: 8px;
  }
  .text-open {
    font-size: 12px;
    color: #44d62c;
  }
  .text-close {
    font-size: 12px;
    color: #d2d2d2;
  }
}
