.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.hidden {
  display: none;
}
.btn-step {
  width: 97px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.filter-action-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
  align-items: center;
}
.filter-container {
  display: flex;
  align-items: center;
}
.filter-container input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}
.filter-container input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
}
.text-error {
  color: #b71c1c;
}
.text-success {
  color: #44d62c;
}
.text-link {
  text-decoration: none;
}
.text-secondary {
  color: rgba(222, 231, 250, 0.8);
}
.text-12 {
  font-size: 12px;
  line-height: 14.32px;
}
.text-14 {
  font-size: 14px;
  line-height: 16.71px;
}
.text-16 {
  font-size: 16px;
  line-height: 19.09px;
  font-weight: 400;
}
.text-18 {
  font-size: 18px;
  line-height: 24.48px;
  font-weight: 600;
}
.text-24 {
  font-size: 24px;
  line-height: 28.64px;
  font-weight: 600;
}

.title-table {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.48px;
  color: #fff;
}
/* table scrollbar */
.table-data::-webkit-scrollbar {
  width: 5px;
}
.table-data::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px gray;
  border-radius: 5px;
}
.table-data::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 5px;
}
.table-data::-webkit-scrollbar-thumb:hover {
  background: gray;
}

/* body scrollbar */
#t2y-app {
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
  position: relative;
}

#t2y-app ::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

#t2y-app ::-webkit-scrollbar-thumb {
  width: 8px;
  cursor: pointer;
  border-radius: 5px;
  background-color: gray !important;
  border: none !important;
}

#t2y-app::-webkit-scrollbar {
  width: 8px !important;
  cursor: pointer;
}
#t2y-app::-webkit-scrollbar-thumb {
  width: 8px !important;
  background-color: gray !important;
  border-radius: 5px !important;
  border: none !important;
}
// #t2y-app::-webkit-scrollbar-thumb:hover {
//   background: gray !important;
// }

/* dialog scrollbar */
.MuiBox-root::-webkit-scrollbar {
  width: 8px !important;
}
.MuiBox-root::-webkit-scrollbar-thumb {
  background: gray !important;
  border-radius: 8px !important;
}
.MuiBox-root::-webkit-scrollbar-thumb:hover {
  background: gray !important;
}
/* ---------- */
.MuiFormLabel-root.Mui-focused {
  color: #fff !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 10px !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #17171700 inset !important;
}
/* licensee */
.list-contact {
  display: flex;
  flex-direction: column;
  min-width: 176px;
  margin-right: 6px;
}
.list-contact span:first-child {
  display: inline;
  margin-bottom: 7px;
}
.action-table {
  display: flex;
  width: 187px;
  justify-content: space-between;
  align-items: center;
}
.action-table div {
  display: flex;
}
/* add licensee */
.selectWorkingHour {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(50vw - 188px);
  margin-bottom: 24px;
  height: 42px;
}
.selectWorkingHour div:first-child {
  display: flex;
}
.list-add-trailer {
  margin-top: -8px;
  margin-right: -10px;
  height: calc(100vh - 240px);
  width: 100%;
  overflow-y: scroll;
}
.count-upsell-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* view licensee trailer table */
.list-item-name {
  min-width: 216px;
  min-height: 58px;
  line-height: 58px;
}
.list-item-detail-to-last {
  min-width: 206px;
}
/* view UpSell Items table */
.list-item-upsell-name {
  min-width: 345px;
}
.list-item-upsell-226 {
  min-width: 226px;
}
.list-item-upsell-last {
  width: 100%;
  min-width: 629px;
}
/* view List Employees item */
.list-employee-name {
  min-width: 305px;
  height: 58px;
  line-height: 58px;
}
.list-employee-item {
  min-width: 270px;
}
.list-employee-item-to-last {
  min-width: 325px;
}
/* rental trailer */
.price {
  position: relative;
}
.price-perDay {
  position: absolute;
  font-size: 16px;
  font-weight: 500;
  top: 16px;
  right: 16px;
  text-align: right;
  color: #44d62c;
}
/* reset email */
.reset-email {
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .logo {
    margin-top: 70px;
    margin-bottom: 57px;
  }
  & .box-main {
    max-width: 70vw;
    border: 1px solid #44d62c;
    border-radius: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 200px 40px;
    & .title {
      color: #000;
      text-align: center;
    }
    & .description {
      color: #313131;
      text-align: center;
    }
    & .break {
      margin: 24px 0;
    }
  }
}
// Name column in tables
.name-column {
  white-space: break-spaces;
}

.carousel.carousel-slider {
  overflow: unset!important;
}
.carousel .control-dots .dot {
  background: #44d62c!important;
}
